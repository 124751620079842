<template>
  <div class="text-center">
    <v-dialog v-model="shower" width="500" v-if="resolutionScreen >= 500">
      <v-card>
        <!-- {{filedata.file_name | subStr}} -->
        <v-card-title
          >{{ $t("statusfileexpired.unsetsettingexpired") }}<v-spacer></v-spacer
          ><v-icon :color="color.theme" size="30" class="pr-2"
            >mdi-file-clock</v-icon
          ></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="text-center mt-3">
          {{ $t("statusfileexpired.unsetexpiredfile") }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="$emit('closedialog'), $emit('resetbutton')"
          >
            {{ $t("uploadfolder.buttonclose") }}
          </v-btn>
          <v-btn
            :color="color.theme"
            dark
            :loading="processdelete"
            @click="$emit('closedialog'), fn_setexfile()"
          >
            {{ $t("checkpassword.ok") }}
          </v-btn>
        </v-card-actions>
        <v-progress-linear
          :active="processdelete"
          indeterminate
          color="green"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-2">
              <span :style="headerPage">{{
                $t("statusfileexpired.unsetsettingexpired")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('closedialog')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text class="px-4 pt-1 pb-1 text-center" :style="titleText">
          <p>{{ $t("statusfileexpired.unsetexpiredfile") }}</p>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('closedialog'), $emit('resetbutton')"
            >{{ $t("uploadfolder.buttonclose") }}</v-btn
          >
          <v-btn
            height="40px"
            width="40%"
            class="elevation-0"
            :style="btnAction"
            :color="color.theme"
            dark
            @click="$emit('closedialog'), fn_setexfile()"
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </div>
        <v-progress-linear
          :active="processdelete"
          indeterminate
          color="green"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import format from "date-fns/format";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import InfiniteLoading from "vue-infinite-loading";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "filedata"],
  data() {
    return {
      datenow: "",
      processdelete: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.datenow = format(new Date(), "yyyy-MM-dd");
        }
        return this.show;
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    },
    //   filters: {
    //   subStr: function (string) {
    //     if (string == null || string == "" || string == undefined) {
    //       return string;
    //     } else {
    //       if (string.length > 30) return string.substring(0, 30) + "...";
    //       else return string;
    //     }
    //   },
    // },
  },
  methods: {
    //ยกเลิกการตั้งค่าวันหมดอายุ
    async fn_setexfile() {
      this.processdelete = true;
      if (this.filedata.file_type === "folder") {
        let payload = {
          folder_id: this.filedata.file_id,
          account_id: this.dataAccountId,
          expiration_time: "00000000000000",
          warning_expiration_time: "0000000",
        };
        let auth = await gbfGenerate.generateToken();
        console.log("payfolder", payload);
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
              "/api/set_folder/expiration_time",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.settime = this.setexpiredfile;
              Toast.fire({
                icon: "success",
                title: this.$t("statusfileexpired.unsetsuccess"),
              });
              this.$emit("closedialogunset");
              this.$emit("reload");
              this.processdelete = false
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("statusfileexpired.unsetunsuccess"),
              });
              this.processdelete = false;
              this.$emit("resetbutton");
            }
          })
          .catch((error) => {
            this.loaddataprogress = false;
            this.processdelete = false;
            console.log(error);
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
      } else {
        let payload = {
          file_id: this.filedata.file_id,
          account_id: this.dataAccountId,
          expiration_time: "00000000000000",
          warning_expiration_time: "0000000",
        };
        let auth = await gbfGenerate.generateToken();
        console.log("payfile", payload);
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_FILE +
              "/api/set_file/expiration_time",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.settime = this.setexpiredfile;
              Toast.fire({
                icon: "success",
                title: this.$t("statusfileexpired.unsetsuccess"),
              });
              this.processdelete = false;
              this.$emit("closedialogunset");
              this.$emit("reload");
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("statusfileexpired.unsetunsuccess"),
              });
              this.processdelete = false;
              this.$emit("resetbutton");
            }
          })
          .catch((error) => {
            this.processdelete = false;
            this.loaddataprogress = false;
            console.log(error);
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
      }
    },
  },
};
</script>
<style>
</style>
